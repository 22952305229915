'use client'

import { useEffect, useState } from "react";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import Image from "next/image";
import { fetchGetAdsFilters } from "@/services/api";
import { useForm } from "react-hook-form";
import { useRouter } from "next/navigation";

function headerStyle() {
    if ($('.main-header').length) {
        var windowpos = $(window).scrollTop();
        var siteHeader = $('.main-header');
        var scrollLink = $('.scroll-to-top');
        var sticky_header = $('.main-header .sticky-header, .main-header .mobile-sticky-header');
        if (windowpos > 250) {
            siteHeader.addClass('fixed-header');
            sticky_header.addClass('fixed-header animated slideInDown');
            scrollLink.fadeIn(300);
            $('.btn-stick').removeClass('d-none');
        } else {
            siteHeader.removeClass('fixed-header');
            sticky_header.removeClass('fixed-header animated slideInDown');
            scrollLink.fadeOut(300);
            $('.btn-stick').addClass('d-none');
        }
    }
}

function Mega_Menu() {
    if ($('.mega-menu').length) {
        var menu_width = $('.mega-menu').attr("data-width");
        $('.mega-menu').width(menu_width);
    }
}

function searchstyle() {
    if ($('.search-modal').length) {
        var btnsearch = $('.button-search');
        var btnclose = $('#btn-search-close');
        var searchmodal = $('.search-modal');
        var inputsearch = $('#inputSearch');
        btnsearch.on('click', function () {
            searchmodal.addClass('search-modal--open');
            inputsearch.focus();
        });
        btnclose.on('click', function () {
            searchmodal.removeClass('search-modal--open');
        });
        var esc = 27;
        $(document).keyup(function (e) {
            if (e.keyCode == esc) $('.search-modal').removeClass('search-modal--open');
        });
    }
}

export default function Header({ company }) {
    const router = useRouter();
    const [filters, setFilters] = useState({ isLoading: true, brands: [], models: [], yearsModel: [], transmissions: [], fuels: [] });
    const { handleSubmit } = useForm();

    useEffect(() => {
        if (filters.isLoading) {
            fetchGetAdsFilters({ condition: null })
                .then(res => { if (res) { setFilters({ isLoading: false, ...res }); } })
                .catch(() => { setFilters({ ...filters, isLoading: false }); });
        }
        else if (!filters.isLoading && filters.brands.length > 0) {
            if ($('#ddlBrandSearchModal').length > 0) $('#ddlBrandSearchModal').niceSelect();
            if ($('#ddlTransmissionSearchModal').length > 0) $('#ddlTransmissionSearchModal').niceSelect();
            if ($('#ddlFuelSearchModal').length > 0) $('#ddlFuelSearchModal').niceSelect();
        }
    }, [filters]);

    useEffect(() => {
        CookieConsent.run({
            guiOptions: {
                consentModal: {
                    layout: "box inline",
                    position: "bottom right",
                    equalWeightButtons: false,
                    flipButtons: false
                },
                preferencesModal: {
                    layout: "box",
                    position: "right",
                    equalWeightButtons: true,
                    flipButtons: false
                }
            },
            categories: {
                necessary: {
                    readOnly: true
                }
            },
            language: {
                default: "en",
                autoDetect: "browser",
                translations: {
                    en: {
                        consentModal: {
                            title: "Você gosta de cookies? 🍪",
                            description: "Nós utilizamos cookies para melhorar a experiência de navegação em nosso site. Ao navegar por nosso site, concorda com nossa política de privacidade",
                            acceptAllBtn: "Aceitar",
                            acceptNecessaryBtn: "Rejeitar",
                            showPreferencesBtn: false,
                            footer: "<a href=\"/politica-de-privacidade\">Veja Nossa Política de Privacidade</a>"
                        }
                    }
                }
            }
        });

        headerStyle();
        Mega_Menu();
        searchstyle();

        if ($('.mobile-menu').length) {
            var mobileMenuContent = $('.main-header .nav-outer .main-menu').html();
            var mobileHeaderContent = $('.main-header .nav-outer .outer-box').html();
            var stickyMenuContent = $('.main-header .main-box').html();
            $('.mobile-header .nav-outer,.mobile-sticky-header .nav-outer').append('<div class="mobile-nav-toggler"><div class="ag-base-block"><div class="js-toggle-4 ag-menu_btn"><div class="ag-menu_line"></div><div class="ag-menu_line__anim"><div class="ag-menu_line__color-1"></div><div class="ag-menu_line__color-2"></div></div><div class="ag-menu_line"></div></div></div></div>');
            $('.mobile-menu .menu-box').append(mobileMenuContent);
            $('.mobile-header .nav-outer').append(mobileHeaderContent);
            $('.sticky-header .main-box').append(stickyMenuContent);
            $('.sticky-header .navbar-collapse').addClass('show');
            $('.mobile-menu .menu-box .navbar-collapse').addClass('show');
            $('.mobile-menu .close-btn, .mobile-menu-back-drop').on('click', function () {
                $('body').removeClass('mobile-menu-visible');
            });
            if ($('.main-header li.dropdown ul').length) {
                $('.main-header .navigation li.dropdown').append('<div class="dropdown-btn"><span class="mdi mdi-chevron-right"></span></div>');
            }
            $('.js-toggle-4').on('click', function () {
                $(this).toggleClass('js-ag-active-4');
            });
            $('.mobile-menu-back-drop').on('click', function () {
                $('.js-toggle-4').toggleClass('js-ag-active-4');
            });
            $('.mobile-menu-close').on('click', function () {
                $('.js-toggle-4').toggleClass('js-ag-active-4');
            });
            $('.mobile-menu li.dropdown .dropdown-btn').on('click', function () {
                $(this).prev('ul').slideToggle(500);
                $(this).toggleClass('active');
                $(this).parent('li').toggleClass('active');
            });
            $('.mobile-menu li.dropdown .dropdown-btn').on('click', function () {
                $(this).prev('.mega-menu').slideToggle(500);
            });
            $(".main-menu .navigation li.dropdown").not("li.has-mega-menu").on('mouseenter', function () {
                $(this).children('ul').stop().slideDown(300);
            });
            $(".main-menu .navigation li.dropdown").not("li.has-mega-menu").on('mouseleave', function () {
                $(this).children('ul').stop().slideUp(300);
            });
            $(".main-menu .navigation > li.has-mega-menu").on('mouseenter', function () {
                $(this).children('.mega-menu').stop().slideDown(300);
            });
            $(".main-menu .navigation > li.has-mega-menu").on('mouseleave', function () {
                $(this).children('.mega-menu').stop().slideUp(300);
            });
            $('.mobile-nav-toggler').on('click', function () {
                $('body').addClass('mobile-menu-visible');
            });
            $('.mobile-menu .menu-backdrop,.mobile-menu .close-button').on('click', function () {
                $('body').removeClass('mobile-menu-visible');
            });
            $('.main-header .cart-btn').on('click', function () {
                $('body').addClass('sidebar-cart-active');
            });
            $('.main-header .cart-back-drop, .main-header .close-cart').on('click', function () {
                $('body').removeClass('sidebar-cart-active');
            });
        }

        if ($('.sticky-sidebar').length) {
            $('.sidebar-side').theiaStickySidebar({
                additionalMarginTop: 100
            });
        }

        $(window).on('resize', function () {
            Mega_Menu();
        });
        $(window).on('scroll', function () {
            headerStyle();
        });
    }, []);

    const onSubmit = () => {
        var searchmodal = $('.search-modal');
        searchmodal.removeClass('search-modal--open');

        const ddlBrand = $('#ddlBrandSearchModal').niceSelect()[0].value;
        const ddlTransmission = $('#ddlTransmissionSearchModal').niceSelect()[0].value;
        const ddlFuel = $('#ddlFuelSearchModal').niceSelect()[0].value;
        let query = {};
        if (ddlBrand) query.marca = ddlBrand;
        if (ddlTransmission) query.cambio = ddlTransmission;
        if (ddlFuel) query.combustivel = ddlFuel;
        router.push(`/estoque?${new URLSearchParams(query)}`);
    }

    return (
        <>

            <header className="main-header">
                <div className="main-box">
                    <div className="auto-container">
                        <div className="menu-box">
                            <div className="logo">
                                <div className="logo-inner">
                                    <a href="/" title={`${company.nickName} a melhor revenda de veículos`}>
                                        <Image
                                            src={`${company.logo}?${performance.now().toString()}`}
                                            alt="logo"
                                            layout="fill"
                                            quality={100}
                                            // loading="lazy"
                                            priority={true}
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="nav-outer">
                                <nav className="main-menu navbar-expand-md navbar-light">
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul className="navigation clearfix">
                                            <li>
                                                <a href="/" title="Home">
                                                    <span>Home</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/estoque" title="Estoque">
                                                    <span>Estoque</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/quem-somos" title="Quem Somos">
                                                    <span>Quem Somos</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/financiamento" title="Financiamento">
                                                    <span>Financiamento</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/venda-seu-veiculo" title="Venda Seu Veículo">
                                                    <span>Venda Seu Veículo</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/seguro" title="Seguro">
                                                    <span>Seguro</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/contato" title="Contato">
                                                    <span>Contato</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky-header">
                    <div className="main-box">
                    </div>
                </div>
                <div className="mobile-header">
                    <div className="logo">
                        <a href="/" title={`${company.nickName} a melhor revenda de veículos`}>
                            <Image
                                src={`${company.logo}?${performance.now().toString()}`}
                                alt="logo"
                                layout="fill"
                                quality={100}
                                // loading="lazy"
                                priority={true}
                            />
                        </a>
                    </div>
                    <div className="outer-box">
                        <div className="search-box">
                            <button className="search-btn button-search">
                                <i className="mdi mdi-magnify"></i>
                            </button>
                        </div>
                    </div>
                    <div className="nav-outer clearfix">
                    </div>
                </div>
                <div className="mobile-sticky-header">
                    <div className="logo">
                        <a href="/" title={`${company.nickName} a melhor revenda de veículos`}>
                            <Image
                                src={`${company.logo}?${performance.now().toString()}`}
                                alt="logo"
                                layout="fill"
                                quality={100}
                                // loading="lazy"
                            />
                        </a>
                    </div>
                    <div className="outer-box">
                        <div className="search-box">
                            <button className="search-btn button-search">
                                <i className="mdi mdi-magnify"></i>
                            </button>
                        </div>
                    </div>
                    <div className="nav-outer clearfix">
                    </div>
                </div>
                <div className="mobile-menu">
                    <span className="mobile-menu-back-drop"></span>
                    <div className="menu-outer">
                        <nav className="menu-box">
                            <div className="nav-logo"></div>
                            <div className="mobile-menu-close">
                                <div className="close-button"></div>
                            </div>
                        </nav>
                        <div className="menu-social">
                            <ul className="social-icons color-hover">
                                {
                                    company.facebook
                                        ? <li className="social-facebook">
                                            <a href={company.facebook} rel="nofollow" target="_blank" title="Facebook">
                                                <i className="mdi mdi-facebook"></i>
                                            </a>
                                        </li>
                                        : null
                                }
                                {
                                    company.instagram
                                        ? <li className="social-instagram">
                                            <a href={company.instagram} rel="nofollow" target="_blank" title="Instagram">
                                                <i className="mdi mdi-instagram"></i>
                                            </a>
                                        </li>
                                        : null
                                }
                                {
                                    company.youtube
                                        ? <li className="social-youtube">
                                            <a href={company.youtube} rel="nofollow" target="_blank" title="YouTube">
                                                <i className="mdi mdi-youtube"></i>
                                            </a>
                                        </li>
                                        : null
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            <div className="search-modal">
                <button id="btn-search-close" className="button-modal btn--search-close" aria-label="Close search form"></button>
                <div className="auto-container">
                    <div className="row align-items-center">
                        <div className="col-md-12 theme-mt-100 theme-mb-30">
                            <div className="w-100 d-block clearfix theme-pl-30 theme-pr-30 theme-pt-30 theme-pb-10 bg-white radius7">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row sidebar-search">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 theme-mb-20">
                                            <div className="form-group">
                                                <i className="mdi mdi-menu"></i>
                                                <select name="ddlBrandSearchModal" id="ddlBrandSearchModal">
                                                    <option value="">Selecione a Marca</option>
                                                    {filters.brands.map((item, index) => <option value={item.name} key={index}>{item.name.toUpperCase()}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 theme-mb-20">
                                            <div className="form-group">
                                                <i className="mdi mdi-cogs"></i>
                                                <select name="ddlTransmissionSearchModal" id="ddlTransmissionSearchModal">
                                                    <option value="">Selecione o Câmbio</option>
                                                    {filters.transmissions.map((item, index) => <option value={item.name} key={index}>{item.name.toUpperCase()}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 theme-mb-20">
                                            <div className="form-group">
                                                <i className="mdi mdi-gas-station"></i>
                                                <select name="ddlFuelSearchModal" id="ddlFuelSearchModal">
                                                    <option value="">Selecione o Combustível</option>
                                                    {filters.fuels.map((item, index) => <option value={item.name} key={index}>{item.name.toUpperCase()}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 theme-mb-20">
                                            <button type="submit" name="SendPostFormSearch" value="SendPostFormSearch" className="btn btn-orange btn-arrow">
                                                <span>PESQUISAR <i className="mdi mdi-magnify"></i></span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}